.card-upload-guidance-section {
  margin-bottom: 14px;

  .card-upload-guidance-title {
    justify-content: space-between;
    display: flex;
    margin: 0 0 10px 0;
    padding: 0;
  }

}
