@import 'src/../node_modules/amazon-hub-asset-commons/assets/stylesheets/amazon_hub_commons/global_variables/_colors';

.vaccination-alert {
  color: $color-grayscale-p0;
  border-radius: 14px;
  background-color: $color-secondary;
  max-width: 360px;
  min-width: 320px;
  position: relative;
  left: calc(50% - 180px);
  display: flex;
  justify-content: space-between;

  .vaccination-alert-icon {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    position: relative;
    top: -5px;
    background-color: white;
    margin-right: 8px;
  }

  .vaccination-alert-message {
    padding: 0 5px;
  }
}