
@import 'bootstrap-sass/assets/stylesheets/bootstrap/variables';
@import 'bootstrap-sass/assets/stylesheets/bootstrap/mixins';
@import 'compass-mixins/lib/_compass';
@import 'amazon-hub-asset-commons/assets/stylesheets/amazon_hub_commons/global_variables/_colors';

@import 'amazon-hub-asset-commons/assets/stylesheets/amazon_hub_commons/components/radio_buttons';

@import '@atoz/atoz-common-ui-components/dist/atoz-common-ui-components.esm.css';

$focus-highlight: 3px solid $color-goldenrod;

a:focus, .btn:focus, .btn-link:focus, button:focus {
    outline: $focus-highlight;
}

a:focus {
    color: $color-link;
}

fallback-error {
    display: flex;
}