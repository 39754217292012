
.skip-confirmation-dialog {

  .title {
    font-weight: bold;
    padding-left: 15px;
  }

  .skip-confirmation-footer {
    display: flex;
    justify-content: space-between;

    .cancel-btn {
      min-width: 120px;
    }

    .proceed-btn {
      min-width: 150px;
    }

  }
}