.test-attestation-form {

  #test-collection-date-picker + div, #test-result-date-picker + div {
    margin: 0 auto;
  }

  .take-photo-icon {
    margin-right: 5px;
  }

  .content {
    margin-top: 0;
    margin-bottom: 20px;
    font-size: 16px;
    line-height: 1.2;
  }

  .bold {
    font-weight: 800;
  }

}

.card-preview-image-section {

  .card-image-box {
    width: 100%;
    border: 1px solid #799294;
    cursor: pointer;
  }

  .card-preview {
    display: block;
    width: 100%;
    margin: auto;
  }

  .card-image-box-tooltip {
    padding-top: 8px;
    font-style: italic;
    text-align: left;
  }

}
