$color-primary-70: #1768c9;

.vaccination-bonus-page, .vaccination-status-page {

  .card-upload-guidance-section {
    margin-bottom: 32px;

    .card-upload-guidance-title {
      justify-content: space-between;
      display: flex;
    }

  }

  .card-image-box {
    padding: 2px 45px;
    background-color: #E9E9E9;
    border: 1px solid #799294;
    cursor: pointer;
  }

  .card-image-box-tooltip {
    padding-top: 8px;
    font-style: italic;
  }

  .card-upload-image-view-title {
    margin-top: 0;
  }

  .bold {
    font-weight: 800;
  }

  .card-upload-replace-btn {
    display: block;
    text-align: center;
    margin-top: 41px;
    font-size: 16px;
  }

  .radio-icon {
    min-width: 24px;
    min-height: 24px;
  }

  h2 {
    font-weight: 600;
  }

  max-width: 600px;
  margin: auto;

  p {
    font-size: 16px;
  }

  a {
    color: $color-primary-tint;

    &:focus {
      outline: 3px solid $color-goldenrod;
    }
  }

  hr {
    margin: 10px 0px;
  }

  .btn {
    &:focus {
      outline: 3px solid $color-goldenrod;
    }
  }

  .rad {
    input {
      visibility: visible;
      opacity: 0;

      &:focus + i {
        outline: 3px solid $color-goldenrod;
      }
    }
  }

  .background {
    padding-top: 16px;
    padding-right: 16px;
    padding-left: 16px;
    padding-bottom: 16px;
    background-color: $color-background-primary;
  }

  .title {
    font-size: 24px;
    line-height: 1.1;
    margin-top: 0;
    margin-bottom: 12px;
  }

  .card-upload-info {
    color: #5C7274;
  }

  .content {
    margin-top: 0;
    margin-bottom: 20px;
    font-size: 16px;
    line-height: 1.2;
  }

  .description {
    font-size: 16px;
    line-height: 1.2;
  }

  .toast-section {

    & > div:nth-child(n):not(:last-child) {
      margin-bottom: 10px;
    }

    margin-bottom: 20px;
  }

  .sub-title {
    font-size: 18px;
    line-height: 1.2;
    color: #161E2D;
    margin: 0 0 16px 0;
  }

  .report-button-section {
    display: flex;
    flex-direction: column;
    margin-top: 1em;

    .btn.btn-secondary {
      margin-right: 0;
    }

    .btn {
      width: 100%;
      margin-bottom: 16px;
    }

    .btn:last-child {
      margin-bottom: 0;
    }
  }

  .helpful-links-list {
    list-style: none;
    padding: 0;
    margin: 0;

    .helpful-links-list-item:not(:last-child) {
      margin-bottom: 12px;
    }
  }

  .helpful-links-list-item > a {
    font-size: 16px;
    line-height: 1.2;
  }

  //====================Vaccine certificate====================
  .select-photo-btn {
    & ~ a {
      font-size: 16px;
      line-height: 1.1;
    }
  }

  .badge-screen-title {
    font-size: 24px;
    line-height: 1.1;
    margin-top: 0;
    margin-bottom: 12px;
  }

  .full-width-btn {
    width: 100%;
  }

  .take-photo-icon {
    margin-right: 5px;
  }

  .section-title {
    font-size: 20px;
    margin: 12px 0;
  }

  .vaccination-card-preview img {
    display: block;
    width: 100%;
    margin: auto;
  }

  .faq-question {
    margin-top: 32px;
    font-size: 18px;
    font-weight: 500;
  }

  .faq-answer {
    a {
      font-weight: bold;
    }
  }

  .vaccination-bonus-radio {
    display: flex;
    margin-bottom: 16px;
    font-weight: 400;
    text-transform: none;
    font-size: 1em;
  }

  .disabled {
    opacity: 0.5;
  }

  .warning {
    color: #666;
    font-style: italic;
    display: table;
    padding-bottom: 10px;

    .st-info-icon {
      padding-right: 20px;
      display: table-cell;
      margin: auto;
    }
  }

  .error {
    color: red;
    display: table;
    padding-bottom: 8px;

    .st-alert-icon-2 {
      padding-right: 20px;
      display: table-cell;
      margin: auto;
    }
  }

  .reporting-history {

    .description {
      margin-bottom: 16px;
      font-size: 16px;
      line-height: 1.2;
    }

    .history-table {
      background-color: $color-white;
      border: 2px solid #dddddd;
      margin-top: 16px;

      .history-table-header {
        background-color: #DDDDDD;
        border: 2px solid #AAAAAA;
        border-bottom: 3px solid #AAAAAA;
      }

      .date-cell {
        // make it as small as possible, but do not wrap
        width: 1px;
        white-space: nowrap;
      }

      .strikethrough {
        text-decoration: line-through;
      }
    }
  }

  .form-group {

    .legal-agreement-wrapper {
      display: flex;

      .help-block {
        margin-top: 0;
      }
    }

    .error-field {
      border: #CD3E3E solid 2px;
    }
  }

  .confirm-section {
    display: flex;
    padding: 6px 0px;

    .confirm-label {
      font-style: italic;
      padding-right: 6px;
    }

    .confirm-content {
      font-weight: bold;
    }
  }

  .work-wo-mask-section {
    border: 1px solid #979797;
    padding: 0 8px;
    border-radius: 3px;
    margin-bottom: 16px;
  }

  .not-eligible {
    text-align: center;
    font-weight: bold;
    margin-top: 16px;
  }

  .vaccination-paper {
    background: #FFFFFF;
    border: 1px solid #979797;
    border-radius: 3px;
    padding: 16px;
    margin-bottom: 20px;
  }

  .toast-box {
    margin: 16px 0;
  }
}

.vaccination-request {
  padding-top: 3px;
}

.vaccination-help-block-text {
  font-weight: bold;
}

//=======================certificate=============================

.proof-of-vaccination-page-wrapper {
  min-height: 680px;
  color: #FFFFFF;
  margin: -5px 0;

  .proof-of-vaccination-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 16px;

    .badge-screen-title {
      color: #FFFFFF;
      font-size: 28px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 1.1;
      margin-top: 0;
      margin-bottom: 16px;
    }

    .top-section {
      display: flex;
      margin: 0 auto 16px;
      align-items: center;

      &-col {
        flex-direction: column;
      }

    }

    .card-valid-subtitle {
      font-size: 18px;
      line-height: 1.3;
      margin-bottom: 16px;
    }

    .current-date, .current-time {
      font-size: 20px;
      line-height: 1.3;
      margin: 0;
    }

    .card-form-link {
      max-width: 328px;
      width: 328px;

      .button-icon {
        position: absolute;
        top: 13px;
        left: 70px;
      }
    }

    .reporting-history-link {
      background: #cccccc;
      display: flex;
      width: 100%;
      justify-content: center;
      max-width: 328px;
      align-items: center;
      border: 1px solid #D1D5DA;
      color: #000000;
      margin-bottom: 0;
      position: relative;

      .reporting-link-chevron {
        position: absolute;
        bottom: 9px;
        right: 10px;
        max-width: 25px;
      }
    }

    .checkmark {
      width: 48px;
      height: 48px;
      margin-right: 12px;
    }

    .employee-photo-wrapper {
      width: 200px;
      margin-bottom: 25px;
    }

    .employee-photo {
      display: block;
      width: 100%;
      height: auto;
    }

    .employee-full-name {
      text-align: center;
      margin-bottom: 25px;
      font-size: 24px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 1.1;
    }

    .days-count {
      font-size: 36px;
      line-height: 26px;
      text-align: center;
      font-weight: bold;
      margin-bottom: 15px;
    }
  }

  .glyphicon-time{
    margin-right: 5px;
  }
}

.vaccination-status-page {
  padding: 0 16px;
}

.mgn-btm-0 {
  margin-bottom: 0;
}

.pad-top-0 {
  padding-top: 0;
}

.mgn-btm-10 {
  margin-bottom: 10px !important;
}

input[type="checkbox"] {
  // Changing display from none to block, zeroing the component's size instead
  display: block;
  height: 0px;
  width: 0px;
  opacity: 0;

  + label {
    // In AssetCommons this value is set to 31px, creating ugly gap between checkbox and outline
    padding-left: 26px;
    // Decreasing padding by 5 need to increase right margin by 5 as well
    margin: 0px 20px 0px 0px;
  }

  &:focus {
    + label {
      outline: $focus-highlight,
    }
  }

  &:disabled {
    opacity: 0.5;
  }
}

[data-test-component="StencilDatePicker"] {
  [data-test-component="DateCell"] {
    color: $color-primary-70;

    &:hover, &:focus {
      color: $color-primary-70;
      outline: 2px solid $color-primary-70;
    }

    ::before {
      box-sizing: content-box;
    }
  }

  [data-test-selected="true"] {
    background-color: $color-primary-70;
    color: white;

    &:hover, &:focus {
      background-color: $color-primary-70;
      color: white;
    }
  }

  [data-test-component="NextMonthSelector"], [data-test-component="PreviousMonthSelector"] {
    color: $color-primary-70;

    &:focus {
      outline: none;

      &::before {
        border-color: $color-primary-70;
      }
    }
  }
}

.card-preview {
  width: 100%;
  margin-bottom: 20px;
}

.processing-blurb {
  text-align: center;
  padding: 20px;
}

.cancel-processing {
  text-align: center;
}

.live-time {
  padding: 12px;
}

.checkmark-title {
  color: white;
  font-weight: bold;
  margin: 0;
  display: block;
  align-items: center;
}

.why-ask-link {
  display: flex;
}

.question-icon {
  margin-right: 7px;
  cursor: pointer;
}

.reset-link-attr {
  cursor: pointer;

  &:hover {
    text-decoration: none;
  }
}
