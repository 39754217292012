$centered-layout-max-width: 500px;

.centered-page {
    max-width: $centered-layout-max-width;
    margin: 10% auto 140px auto;
}

.row-eq-height {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
}