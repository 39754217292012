.green-watermark {
    height: 100%;
    background-image: url("./watermark.svg"), linear-gradient(180deg, #00BBAC 0%, #006C62 34.66%, #00BBAC 66.8%, #006C62 100%);
}

.blue-watermark {
    height: 100%;
    background-image: url("./watermark.svg"), linear-gradient(180deg, #0B7BAA 0%,#5DC9FC 34.66%,#0B7BAA 66.8%, #22A2DF 100%);
}

.red-watermark {
    height: 100%;
    background-image: url("./watermark.svg"), linear-gradient(180deg, #DA3733 0%,#FE5145 34.66%,#9A1818 66.8%,#DA3733 100%);
}